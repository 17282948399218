import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';

import Landinghomebanner from '../../assets/new-getx-images/landing-home-banner-2.png';
import Bannerimage3 from '../../assets/new-getx-images/banner-image-3.png';
import Bannerimage4 from '../../assets/new-getx-images/banner-image-4.png';
import Bannerimage5 from '../../assets/new-getx-images/banner-image-5.png';
import Bannerimage6 from '../../assets/new-getx-images/banner-image-6.png';
import BtcLogo from '../../assets/new-getx-images/coin-logo/btc.png';
import Eth from '../../assets/new-getx-images/coin-logo/Eth.png';
import Tether from '../../assets/new-getx-images/coin-logo/Tether.png';
import Solana from '../../assets/new-getx-images/coin-logo/Solana.png';
import Xrp from '../../assets/new-getx-images/coin-logo/Xrp.png';
import Usdc from '../../assets/new-getx-images/coin-logo/Usdc.png';
import Graphwave from '../../assets/new-getx-images/banner-graph-wave.png';
import Footeriosand from '../../assets/new-getx-images/footer-ios-and.png';
import Googleplay from "../../assets/images/google-play.png";
import Ios from "../../assets/images/app-store.png";
import DummyQR from "../../assets/images/dummy-QR.png";
import { TbHeartRateMonitor } from "react-icons/tb";
import { HiSpeakerphone } from "react-icons/hi";

import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { FaChevronRight } from "react-icons/fa";
import Slide1 from '../../assets/images/slides/slide-1.jpg';
import Blog1 from '../../assets/images/blog/blog1.webp';
import Blog2 from '../../assets/images/blog/blog2.webp';
import Blog3 from '../../assets/images/blog/blog3.webp';

import Getxattestation from '../../assets/whitepaper/Letter_of_attestation__-_GetX_web_Application.pdf';


import {
  getHomeCMS
} from '../../core/services/all.api';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { IoCheckmarkCircle, IoShieldHalfSharp } from "react-icons/io5";
import { LuMonitorCheck } from "react-icons/lu";
import { RiBarChartBoxLine } from "react-icons/ri";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { getCookie } from "../../core/helper/cookie";
import { makeRequest } from "../../core/services/v1/request";
import Config from "../../core/config"
import Logofloat from "../../assets/images/getxlogo.png"
import QRCode from "react-qr-code";
import { FaGift } from "react-icons/fa";
import Marquee from "react-fast-marquee";
import Redbox from "../../assets/new-getx-images/red-box.png"


function Mainbanner(props) {

  const navigate = useNavigate();
  const [focus, setFocus] = React.useState(false);
  const [cmsData, setCmsData] = useState([]);
  const [banner, setBanner] = useState([]);
  const [sortBNB, setSortBNB] = useState([]);
  const [hotPairs, setHotPairs] = useState([]);
  const [topGainersPairs, setTopGainersPairs] = useState([]);
  const [newGetXCoin, setNewGetXCoin] = useState([]);
  const token = getCookie("userToken");
  const [isVisible, setIsVisible] = useState(true);
  const [isLoggedIn, setisLoggedIn] = useState(null);

  const handleClose = (e) => {
    e.stopPropagation();
    setIsVisible(false);
  };


  useEffect(() => {
    $(document).ready(function () {
      $(".table-view-all-button-1").click(function () {
        $(".table-view-all-button-1").hide();
        $(".table-view-all-button-2").show();
        $(".top-banner-third-section table tbody tr").show();
      });
      $(".table-view-all-button-2").click(function () {
        $(".table-view-all-button-2").hide();
        $(".table-view-all-button-1").show();
        $(".top-banner-third-section table tbody tr").hide();
        $(".top-banner-third-section table tbody tr:nth-child(-n+6)").css("display", "table-row");
      });
    });
  }, []);

  const isValidHttpUrl = async (val = "") => {
    let url;
    try {
      url = new URL(val);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  useEffect(() => {
    getHomeCMSCall();
    getCurrency();
  }, []);

  const navigateTo = async (linkVal) => {
    if (linkVal) {
      if (await isValidHttpUrl(linkVal)) {
        window.open(linkVal);
      }
      else {
        navigate(linkVal, true);
      }
    }
  }

  async function getCurrency() {
    const params = {
      url: `${Config.V1_API_URL}wallet/getCurrencyList`,
      method: 'GET'
    }
    const response = (await makeRequest(params));
    if (response.status) {
      let sortedBNB = response.data?.filter(item => item.currencySymbol === "BNB");
      // let CurrencyLists = response.CurrencyList?.sort((a, b) => b.currencySymbol.localeCompare(a.currencySymbol));
      // let highVolumeCur = response.CurrencyList?.sort((a, b) => b.userDet.volume - a.userDet.volume);
      // let newGetXCoin = response.data?.slice(0, 6);
      setSortBNB(sortedBNB);
      setHotPairs(response.hotPairs);
      setTopGainersPairs(response.topGainers);
      setNewGetXCoin(response.newOnGetx);
    };
  }

  const getHomeCMSCall = async () => {
    const payload = { from: "home" };
    const resp = await getHomeCMS({ payload });
    if (resp) {
      const {
        getcmsDetails = [],
        status = false
      } = resp;
      if (status) {
        const adBanner = getcmsDetails?.filter((elem) => (elem.identify == "adBanner" && elem.image != ""));
        setBanner(adBanner);
        const homeIntro = getcmsDetails?.filter((elem) => (elem.identify == "homeIntro"));
        const ourBenifit = getcmsDetails?.filter((elem) => (elem.identify == "our-benefits"));
        let section1 = getcmsDetails?.filter((elem) => (elem.identify == "section1"));
        let section2 = getcmsDetails?.filter((elem) => (elem.identify == "section2"));
        let section3 = getcmsDetails?.filter((elem) => (elem.identify == "section3"));
        let section4 = getcmsDetails?.filter((elem) => (elem.identify == "section4"));
        let section5 = getcmsDetails?.filter((elem) => (elem.identify == "section5"));
        let CMSList = getcmsDetails?.filter((elem) => (elem.identify == "CMS"));
        const updateCmdData = {
          adBanner,
          ourBenifit,
          homeIntro: homeIntro[0] ? homeIntro[0] : {},
          section1: section1[0] ? section1[0] : {},
          section2: section2[0] ? section2[0] : {},
          section3: section3[0] ? section3[0] : {},
          section4: section4[0] ? section4[0] : {},
          section5: section5[0] ? section5[0] : {},
          CMSList: (CMSList && CMSList.length > 0) ? CMSList : []
        };
        setCmsData(updateCmdData);
      }
    }
  }

  const checkLogin = () => {
    const LoggedIn = getCookie('userToken');
    setisLoggedIn(LoggedIn)
    if (LoggedIn) {
      navigate("/my/voucher");
    } else {
      navigate("/login")
    }
  }
  return (
    <>
      {isVisible && (
        // <Link to="/login">
        <div className="adv-off-sec-1 mt-3">
          <button
            className="adv-off-sec-1-close"
            onClick={handleClose}
          >
            X
          </button>
          <div onClick={checkLogin} className="curPointer">
            <img src={Redbox} alt="Redbox" />
            <p className="mb-0 adv-off-sec-1-1 mt-2">Register Now</p>
            <p className="mb-0 adv-off-sec-1-2">Claim your</p>
            <p className="mb-0 adv-off-sec-1-3">25 USDT</p>
            <p className="mb-0 adv-off-sec-1-4">Welcome BONUS</p>
          </div>
        </div>
        // </Link>
      )
      }
      <section className='top-banner-home-section top-banner-home-section-padding position-relative'>
        {/* <img src={Logofloat} alt='float-coin' className="top-banner-home-float-1" /> */}
        <div className='container py-3'>
          <div className='row g-4 align-items-center justify-content-center flex-lg-row-reverse'>
            <div className='col-lg-6 text-center position-relative'>
              <div className="floating-css-glow-1"></div>
              <img src={Landinghomebanner} alt='future of money' className="w-75 position-relative z-1" />
            </div>
            <div className='col-lg-6'>
              <h1 className='banner-text-1 mb-4'><span className='banner-text-1-1'><span className='banner-text-1-2'>Trade , Earn</span></span> and secure with confidence </h1>
              <p className='banner-text-2 mb-5'>Experience low fee, Fast transactions, high ROI and world class security</p>
              {/* <p className='banner-text-2 mb-4'><FaGift className="mb-1 fc-b me-1" /> Register now and receive <span className="fc-b">25 USDT</span> as a welcome bonus.</p> */}
              <div className="d-flex gap-2">
                {token ? (
                  <a onClick={() => navigate('/spot/GTX_USDT')} className='banner-button-2 banner-button-2-hover-eff'>Trade Now</a>
                ) : (
                  <a onClick={() => navigate('/register')} className='banner-button-2 banner-button-2-hover-eff'>Trade Now</a>
                )}

                <a href={Getxattestation} target="_blank" className='banner-button-2-22'>Security Audited <IoCheckmarkCircle className="banner-button-2-22-icons" /></a>

              </div>


              {/* <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Email Address" />
                <button className="banner-button-1" type="button" id="button-addon1">Submit</button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <div className="top-banner-home-section-2-marquee ">
        <div className='container'>
          <div className="d-flex mx-auto align-items-center text-white">
            <h3><HiSpeakerphone className="me-3" /></h3>
            <Marquee speed={70} gradient={false} pauseOnHover={true} autoFill={true}>
              {
                banner.map((data) => {
                  return (
                    <a target="_blank" href={data.link} style={{ margin: '0 50px' }}>
                      {data.titlelink}
                    </a>
                  )
                })
              }
            </Marquee>
          </div>
        </div>
      </div>

      <section className='top-banner-home-slide-section top-banner-home-section-2 pt-lg-4 pb-0'>
        <div className='container'>
          <div className='row'>
            <p className="banner-slide-text-1 mb-0"><HiSpeakerphone className="banner-slide-text-2 me-1" /> Announcement</p>

            <div className='col-lg-12'>
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                navigation={true}

                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}

                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  800: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1100: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                modules={[Autoplay, Navigation]}
                className="pt-4"
              >
                {
                  banner?.map((data) => {
                    return (
                      <SwiperSlide>
                        <a target="_blank" href={data.link}>
                          <img src={data.image} alt="anouncement" />
                        </a>
                      </SwiperSlide>
                    )
                  })
                }
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className='top-banner-home-section-2'>
        <div className='container'>
          <div className='row g-4'>
            <div className='col-lg-6'>
              <h2 className='banner-text-3 mb-4'><span style={{ color: "var(--btnClr)" }}>Trade</span>, <span style={{ color: "var(--btnClr)" }}>Invest</span>, and <span style={{ color: "var(--btnClr)" }}>Monitor</span> with Ease
                {/* <span style={{ color: "var(--btnClr)" }}> Bitcoin </span> to <span style={{ color: "var(--btnClr)" }}>Dogecoin</span>,  we got your back */}
              </h2>
              <p className='banner-text-7 mb-lg-5 w-100'>Manage your favourite cryptocurrencies all in one place, anytime, anywhere.</p>
            </div>
            <div className='col-lg-6'>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-Tradable-tab" data-bs-toggle="pill" data-bs-target="#pills-Tradable" type="button" role="tab" aria-controls="pills-Tradable" aria-selected="true">Hot Pairs🔥</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Top-Gainers-tab" data-bs-toggle="pill" data-bs-target="#pills-Top-Gainers" type="button" role="tab" aria-controls="pills-Top-Gainers" aria-selected="false">Top Gainers</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-Coinbase-tab" data-bs-toggle="pill" data-bs-target="#pills-Coinbase" type="button" role="tab" aria-controls="pills-Coinbase" aria-selected="false">New on GetX</button>
                </li>
              </ul>
              <div className="tab-content mt-4" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-Tradable" role="tabpanel" aria-labelledby="pills-Tradable-tab" tabindex="0">
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    {hotPairs && hotPairs.length > 0 ?
                      hotPairs?.map((item, idx) => (
                        <div className="col" key={idx}>
                          <div className="card h-100" onClick={() => navigate(`/spot/${item.pair}`)}>
                            <div className="card-body">
                              {/* <a onClick={navigate(`/spot/${item?.userDet.pair}`)}></a> */}
                              <img src={item?.fromCurrencyDet.image} className='home-2-coin-logo' alt='CoinLogo' />
                              <h5 className='banner-text-4 mt-2'>{item.fromCurrencyDet.currencySymbol}</h5>
                              <h5 className="banner-text-5">${(item.usdPrice)?.toFixed(5)}</h5>
                              {
                                item.changePer.toString().includes("-") ?
                                  <p className="banner-text-6 fc-r"><GoArrowDown />{item.changePer.toFixed(2)}</p>
                                  :
                                  <p className="banner-text-6 fc-g"><GoArrowUp />{item.changePer.toFixed(2)}</p>
                              }
                              <img src={Graphwave} alt='Graphwave' className='banner-image-2' />
                            </div>
                          </div>
                        </div>
                      ))
                      : null
                    }
                    {/* {sortBNB.length > 0 && (
                      <div className="col">
                        <div className="card h-100">
                          <div className="card-body">
                            <img src={sortBNB[0]?.image} className='home-2-coin-logo' alt='CoinLogo' />
                            <h5 className='banner-text-4 mt-2'>{sortBNB[0]?.currencySymbol}</h5>
                            <h5 className="banner-text-5">$3,616,702.87</h5>
                            <p className="banner-text-6 fc-g"><GoArrowUp />2.87%</p>
                            <img src={Graphwave} alt='Graphwave' className='banner-image-2' />
                          </div>
                        </div>
                      </div>
                    )} */}

                  </div>
                </div>
                <div className="tab-pane fade" id="pills-Top-Gainers" role="tabpanel" aria-labelledby="pills-Top-Gainers-tab" tabindex="0">
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    {topGainersPairs && topGainersPairs.length > 0 ?
                      topGainersPairs.map((item, idx) => (
                        <div className="col" key={idx}>
                          <div className="card h-100" onClick={() => navigate(`/spot/${item.pair}`)}>
                            <div className="card-body">
                              <img src={item.fromCurrencyDet.image} className='home-2-coin-logo' alt='CoinLogo' />
                              <h5 className='banner-text-4 mt-2'>{item.fromCurrencyDet.currencySymbol}</h5>
                              <h5 className="banner-text-5">${(item.usdPrice)?.toFixed(5)}</h5>
                              {
                                item.changePer.toString().includes("-") ?
                                  <p className="banner-text-6 fc-r"><GoArrowDown />{item.changePer.toFixed(2)}</p>
                                  :
                                  <p className="banner-text-6 fc-g"><GoArrowUp />{item.changePer.toFixed(2)}</p>
                              }
                              <img src={Graphwave} alt='Graphwave' className='banner-image-2' />
                            </div>
                          </div>
                        </div>
                      ))
                      : null
                    }
                    {/* {sortBNB.length > 0 && (
                      <div className="col">
                        <div className="card h-100">
                          <div className="card-body">
                            <img src={sortBNB[0]?.image} className='home-2-coin-logo' alt='CoinLogo' />
                            <h5 className='banner-text-4 mt-2'>{sortBNB[0]?.currencySymbol}</h5>
                            <h5 className="banner-text-5">$3,616,702.87</h5>
                            <p className="banner-text-6 fc-g"><GoArrowUp />2.87%</p>
                            <img src={Graphwave} alt='Graphwave' className='banner-image-2' />
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-Coinbase" role="tabpanel" aria-labelledby="pills-Coinbase-tab" tabindex="0">
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    {
                      newGetXCoin && newGetXCoin.length > 0 ?
                        newGetXCoin.map((item) => (
                          <div className="col" key={item._id}>
                            <div className="card h-100" onClick={() => navigate(`${item?.userDet.pair}`)}>
                              <div className="card-body">
                                <img src={item.image} className='home-2-coin-logo' alt='CoinLogo' />
                                <h5 className='banner-text-4 mt-2'>{item.currencySymbol}</h5>
                                <h5 className="banner-text-5">${item.USDvalue}</h5>
                                {/* <p className="banner-text-6 fc-g"><GoArrowUp />2.87%</p> */}
                                <img src={Graphwave} alt='Graphwave' className='banner-image-2' />
                              </div>
                            </div>
                          </div>
                        ))
                        : null
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='top-banner-home-section-3'>
        <div className='container'>
          <div className='row g-4 align-items-center justify-content-between'>
            <div className='col-lg-5'>
              <img src={Bannerimage3} alt='Bannerimage3' />
            </div>
            <div className='col-lg-6'>
              <p className='banner-text-3 mb-3 w-100'>Be the master of your financial journey</p>
              <h2 className='banner-text-2 mb-5'>It's never too late to begin your investment journey.</h2>
              {token ? (
                <a onClick={() => navigate('/spot/GTX_USDT')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              ) : (
                <a onClick={() => navigate('/register')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              )}
              {/* <a href='/' className='banner-button-2 banner-button-2-hover-eff'>Learn More</a> */}
              {/* <div className='top-banner-home-section'>
                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder="Email Address" />
                  <button className="banner-button-1" type="button" id="button-addon1">Submit</button>
                </div>
              </div> */}
            </div>

          </div>
        </div>
      </section>

      <section className='top-banner-home-section-8'>
        <div className='container'>
          <div className='row g-4 align-items-center'>
            <div className='col-lg-6 text-lg-center'>
              <img src={Bannerimage4} style={{ width: "90%" }} alt='Bannerimage3' />
            </div>
            <div className='col-lg-6'>
              <p className='banner-text-3 mb-3 w-100'>Store your cryptocurrencies <span style={{ color: "var(--btnClr)" }}>securely</span></p>
              <h2 className='banner-text-7 mb-lg-5 mb-3'>Trading Bitcoin or Shiba Inu? Our platform offers lightning-fast transactions with advanced world-class cold storage.</h2>
              {token ? (
                <a onClick={() => navigate('/spot/GTX_USDT')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              ) : (
                <a onClick={() => navigate('/register')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              )}
              {/* <a href='/' className='banner-button-2 banner-button-2-hover-eff'>Learn More</a> */}
            </div>

          </div>
        </div>
      </section>
      <section className='top-banner-home-section-8'>
        <div className='container'>
          <div className='row g-4 align-items-center flex-lg-row-reverse'>
            <div className='col-lg-6 text-lg-center'>
              <img src={Bannerimage5} style={{ width: "90%" }} alt='Bannerimage3' />
            </div>
            <div className='col-lg-6'>
              <p className='banner-text-3 mb-3 w-100'><span style={{ color: "var(--btnClr)" }}>Elite tools</span>, <span style={{ color: "var(--btnClr)" }}>Customized</span> for the Expert Trader</p>
              <h2 className='banner-text-7 mb-lg-5 mb-3'>The future of trading technology with Getx's advanced analytics and top-notch security.  With real-time order books, top market liquidity, and comprehensive charting tools.</h2>
              {token ? (
                <a onClick={() => navigate('/spot/GTX_USDT')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              ) : (
                <a onClick={() => navigate('/register')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              )}
              {/* <a href='/' className='banner-button-2 banner-button-2-hover-eff'>Learn More</a> */}
            </div>
          </div>
        </div>
      </section>

      <section className='top-banner-home-section-5 text-white'>
        <div className='container'>
          <div className='row g-4 pt-5'>
            <div className='col-lg-6'>
              <h2 className='banner-text-3 mb-0'>Get started in a Few Clicks</h2>
            </div>
            <div className='col-lg-6'>
              <p className='banner-text-7 mb-lg-5 mb-4 text-white'>Ready to invest? Sign up, link your bank account, and start trading.</p>
              {token ? (
                <a onClick={() => navigate('/spot/GTX_USDT')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              ) : (
                <a onClick={() => navigate('/register')} className='banner-button-2 banner-button-2-hover-eff'>Register Today</a>
              )}
              {/* <a href='/register' className='banner-button-2 bg-white text-dark'>Register Today</a> */}
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
            <div className="col">
              <div className="card h-100 text-white">
                <div className="card-body">

                  <h5 className="card-title">$
                    <CountUp start={focus ? 0 : null} end={145} duration={4} redraw={true}>
                      {({ countUpRef }) => (
                        <VisibilitySensor onChange={(isVisible) => {
                          if (isVisible) { setFocus(true); }
                        }}>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    B</h5>
                  <p className="card-text">QUARTERLY VOLUME TRADED</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 text-white">
                <div className="card-body">
                  <h5 className="card-title">
                    <CountUp start={focus ? 0 : null} end={100} duration={4} redraw={true}>
                      {({ countUpRef }) => (
                        <VisibilitySensor onChange={(isVisible) => {
                          if (isVisible) { setFocus(true); }
                        }}>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +</h5>
                  <p className="card-text">COUNTRIES SUPPORTED</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 text-white">
                <div className="card-body">
                  <h5 className="card-title">$
                    <CountUp start={focus ? 0 : null} end={130} duration={4} redraw={true}>
                      {({ countUpRef }) => (
                        <VisibilitySensor onChange={(isVisible) => {
                          if (isVisible) { setFocus(true); }
                        }}>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    B</h5>
                  <p className="card-text">ASSETS ON PLATFORM</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='top-banner-home-section-6 '>
        <div className='container'>
          <div className='d-flex flex-lg-row flex-column align-items-center'>
            <div>
              <h2 className='banner-text-3 mb-2'>Your <span style={{ color: "var(--btnClr)" }}>trusted partner</span> for reliable cryptocurrency trading </h2>
              <p className='banner-text-7 mb-3 w-100'>Millions trust us and we offer a secure platform for buying, selling, and overseeing cryptocurrency investments.</p>
            </div>
            <div className='ms-lg-auto me-auto'>
              {token ? (
                <a onClick={() => navigate('/spot/GTX_USDT')} className='banner-button-2 banner-button-2-hover-eff'>Join Now</a>
              ) : (
                <a onClick={() => navigate('/register')} className='banner-button-2 banner-button-2-hover-eff'>Join Now</a>
              )}
              {/* <a href='/register' className='banner-button-2 banner-button-2-hover-eff'>Join Now</a> */}
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-4 g-4 mt-5">
            <div className="col">
              <div className="card h-100">
                <div className="card-body">
                  <div className='banner-card-icon-1 banner-card-icon-1-1' style={{ backgroundColor: "#EB5757" }}><TbHeartRateMonitor /></div>
                  <h5 className="card-title">The leading cryptocurrency Exchange</h5>
                  <p className="card-text">We offer clear and transparent financial reporting.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body">
                  <div className='banner-card-icon-1 banner-card-icon-1-2' style={{ backgroundColor: "#1464F8" }}><IoShieldHalfSharp /></div>
                  <h5 className="card-title">Your investments are safeguarded</h5>
                  <p className="card-text">We ensure the safety of your assets with risk management.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body">
                  <div className='banner-card-icon-1 banner-card-icon-1-3' style={{ backgroundColor: "#219652" }}><LuMonitorCheck /></div>
                  <h5 className="card-title">Best-in-class support system</h5>
                  <p className="card-text">Need assistance? Reach out to our support team for solutions.</p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body">
                  <div className='banner-card-icon-1 banner-card-icon-1-4' style={{ backgroundColor: "rgb(237 144 70)" }}><RiBarChartBoxLine /></div>
                  <h5 className="card-title">Highest industry standards</h5>
                  <p className="card-text">We provide transactions on multiple popular cryptocurrencies.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='top-banner-home-section-8'>
        <div className='container'>
          <div className='row g-4 align-items-center flex-lg-row-reverse'>
            <div className='col-lg-6 text-lg-center'>
              <img src={Bannerimage6} style={{ width: "90%" }} alt='Bannerimage3' />
            </div>
            <div className='col-lg-6'>
              <p className='banner-text-3 mb-3 w-100'>Interested in crypto? Explore some basic concepts</p>
              <h2 className='banner-text-7 mb-lg-5 mb-3'>Beginner guides, practical tips, and market updates just for you.</h2>
              {token ? (
                <a onClick={() => navigate('/faq')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              ) : (
                <a onClick={() => navigate('/register')} className='banner-button-2 banner-button-2-hover-eff'>Learn More</a>
              )}
              {/* <a href='' className='banner-button-2'>Learn More</a> */}

            </div>

          </div>
        </div>
      </section>

      <section className='top-banner-home-section-9'>
        <div className='container'>
          <div className='row g-4 align-items-center flex-lg-row-reverse'>
            <div className='col-lg-6'>
              <p className='banner-text-1 mb-1'>Start Now</p>
              <p className='mb-4 fw-bold'><span style={{ color: "var(--btnClr)" }}>DOWNLOAD</span> OUR APP TO STAY CONNECT 24/7</p>
              <div className='d-flex flex-row gap-3 align-items-center'>
                <QRCode value="https://play.google.com/store/apps/details?id=com.getx.exchange" className="qr-code-banner-image bg-white p-2" />
                <div>
                  <h5 className=''>Scan to download APP</h5>
                  <h5>Android and iOS supported</h5>

                </div>
              </div>
              <div className='d-flex gap-2 mt-3'>
                <a href="https://play.google.com/store/apps/details?id=com.getx.exchange" className="hover-hand-csss"><img className='footer-appk-download-btn' src={Googleplay} alt='googleplay' /></a>
                <a href="/coming-soon" className="hover-hand-csss"><img className='footer-appk-download-btn' src={Ios} alt='Ios' /></a>
              </div>
            </div>
            <div className='col-lg-6 position-relative'>
              <img src={Footeriosand} className='apk-download-section-image-1' alt='Bannerimage3' />
            </div>


          </div>
        </div>
      </section>


      <section className='top-banner-home-section-4 pt-5'>
        <div className='container top-banner-home-section-4-1 pt-4'>
          <div className='row g-4'>
            <div className='col-lg-6'>
              <h2 className='banner-text-3 mb-0'>Access Crypto Anywhere at any time</h2>
            </div>
            <div className='col-lg-6'>
              <p className='banner-text-7 text-white mb-5'>Our mission is to avail crypto for everyone with top-notch security and advanced tools.</p>
              {token ? (
                <a onClick={() => navigate('/spot/GTX_USDT')} className='banner-button-2 bg-white text-dark banner-button-2-hover-eff-2'>Learn More</a>
              ) : (
                <a onClick={() => navigate('/register')} className='banner-button-2 bg-white text-dark banner-button-2-hover-eff-2'>Learn More</a>
              )}
              {/* <a href='' className='banner-button-2 bg-white text-dark'>Learn More</a> */}
            </div>
          </div>
        </div>
      </section>

      <section className='top-banner-home-section-7 '>
        <div className='container'>
          <div className='d-flex flex-lg-row flex-column align-items-center '>
            <div className="">
              <h2 className='banner-text-3 mb-2'><span style={{ color: "var(--btnClr)" }}>New to crypto?</span> Learn some crypto basics</h2>
              <p className='banner-text-7 mb-3 '>Beginner guides, practical tips, and market updates for first-timers.</p>
            </div>
            <div className='ms-lg-auto me-auto'>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
            <div className="col">
              <div className="card h-100">
                <div className="card-body pb-0">
                  <h5 className="blog-banner-text-1">Discover the Uniqueness of GetX: What Sets It Apart from Other Crypto Exchanges</h5>
                  <p className="blog-banner-text-2">In the crowded landscape of cryptocurrency exchanges, GetX stands out with distinctive features and unique selling points that offer unparalleled advantages to traders.</p>
                </div>
                <div className="card-footer bg-transparent border-0 position-relative">
                  <a target="_blank" href='https://medium.com/@getxexchange/discover-the-uniqueness-of-getx-what-sets-it-apart-from-other-crypto-exchanges-c784f8f8fef7' className='banner-blog-btn-1'>Read more</a>
                  <img src={Blog1} className="blog-card-image-1" alt="blog" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body pb-0">
                  <h5 className="blog-banner-text-1">What’s Next: Exciting Campaigns and Reward Opportunities Ahead</h5>
                  <p className="blog-banner-text-2">As GetX Exchange gears up for its official launch, we are thrilled to announce a series of exciting trading campaigns and airdrop rewards designed to elevate your trading experience. Read on to discover the thrilling opportunities we have in store for you this June!</p>
                </div>
                <div className="card-footer bg-transparent border-0 position-relative">
                  <a target="_blank" href='https://medium.com/@getxexchange/whats-next-exciting-campaigns-and-reward-opportunities-ahead-70e12d70ac0f' className='banner-blog-btn-1'>Read more</a>
                  <img src={Blog2} className="blog-card-image-1" alt="blog" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100">
                <div className="card-body pb-0">
                  <h5 className="blog-banner-text-1">Unleashing Advanced Features for a Superior Trading Experience</h5>
                  <p className="blog-banner-text-2">At GetX Exchange, we are committed to providing our users with cutting-edge tools and features that enhance their trading experience. Discover how our comprehensive suite of advanced features sets us apart from the competition and empowers you to trade with confidence and precision.</p>
                </div>
                <div className="card-footer bg-transparent border-0 position-relative">
                  <a target="_blank" href='https://medium.com/@getxexchange/unleashing-advanced-features-for-a-superior-trading-experience-ad38a4caa62e' className='banner-blog-btn-1'>Read more</a>
                  <img src={Blog3} className="blog-card-image-1" alt="blog" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>

  );
}

export default Mainbanner;
