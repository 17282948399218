import React, { useEffect, useState } from "react";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormat } from '../../../core/helper/date-format';
import { Link, useNavigate } from "react-router-dom";
import Pagination from 'react-responsive-pagination';
import '../../../pagination.css';
import notFound from "../../../assets/images/no-history.gif";
import depthNotFound from "../../../assets/images/No-Depth-Orders.gif"
import { getOrderDetail_API } from "../../../core/services/all.api";
import TradeHistoryTable from './TradeHistoryTable';
import TradeHistoryDetail from './TradeHistoryDetail';
import DepthChart from "../depthChart/DepthChart";
import { formatWithoutRounding, toFixedWithoutRound } from "../../../core/helper/common";
export default function MyOrder(props) {
  const {
    myProfile = {},
    userTradeDetails = {},
    setCurrentPage_tradeHis,
    currentPage_tradeHis,
    pageChange,
    recordsPerPage,
    bids,
    asks,
    symbol,
    isBinancePair,
    binanceDepthOrders
  } = props;
  const navigate = useNavigate();

  const [traHisDetails, setTraHisDetails] = useState({});
  const [userHistoryTab, setUserHistoryTab] = useState("open orders");
  const [startDate, setStartDate] = useState(new Date("2014/02/08"));
  const [endDate, setEndDate] = useState(new Date("2014/02/10"));

  useEffect(() => {
    $(".status_change .dropdown-item").click(function () {
      var getStatusText = $(this).text();
      $(this).closest(".status_dropdown").find(".status__btn").text(getStatusText);
      var generateStatusClass = `${$(this).attr('data-class')}-status`
      $(this).closest(".status_dropdown").attr("data-color", `${generateStatusClass}`);
    })
  }, []);

  const clickPageNo = (pgNumber) => {
    pageChange(pgNumber);
  }



  // const copyText = (data = {}) => {
  //   if (data.text) {
  //     var input = document.createElement("input");
  //     document.body.appendChild(input);
  //     input.value = data.text;
  //     input.select();
  //     document.execCommand("Copy");
  //     input.remove();
  //     toast({ type: "success", message: data.message });
  //   }
  // }

  const getOrderDetail_call = async (orderId) => {
    setTraHisDetails({});
    const data = {
      payload: {
        orderId: orderId,
      },
    };
    const resp = await getOrderDetail_API(data);
    if (resp.status === true && resp.response) {
      setTraHisDetails(resp.response);
    }
  };


  return (
    <div>
      <div className='d-flex flex-row trading-page-order-details-section '>
        <div className=' col-12 '>
          <div className="d-flex flex-lg-row flex-column paired-trade-table-section px-3 align-items-center">
            <div className="">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("open orders")}>
                  <button
                    className="nav-link active"
                    id="pills-openorders-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-openorders"
                    type="button" role="tab"
                    aria-controls="pills-openorders"
                    aria-selected="false"
                  >
                    Open Order({props.userTradeDetails?.activeOrders && props.userTradeDetails?.activeOrders?.length})
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Stop Limit Orders")}>
                  <button
                    className="nav-link"
                    id="pills-orderhistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-orderhistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-orderhistory"
                    aria-selected="false"
                  >
                    Stop Limit Order({props.userTradeDetails.stopOrders && props.userTradeDetails.stopOrders.length})
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("Trade History")}>
                  <button
                    className="nav-link"
                    id="pills-tradehistory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-tradehistory"
                    type="button"
                    role="tab"
                    aria-controls="pills-tradehistory"
                    aria-selected="false"
                  >
                    Trade History
                  </button>
                </li>
                <li className="nav-item" role="presentation" onClick={() => setUserHistoryTab("depth-chart")}>
                  <button
                    className="nav-link"
                    id="pills-depthchart-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-depthchart"
                    type="button"
                    role="tab"
                    aria-controls="pills-depthchart"
                    aria-selected="false"
                  >
                    Depth Chart
                  </button>
                </li>

                <li className="nav-item d-flex flex-row align-items-center">
                  <span className="nav-link me-0">Hide Other Pairs</span>&nbsp;
                  <input type="checkbox" name="hideOtherPair" className="mb-2" onClick={() => props.toggleHidePair()} />
                </li>
                {/* <li className="nav-item">
                  <button className="popup-modal-button-my-orders" onClick={()=>copyText({
                    message: "Order ID copied successfully!",
                    text: "Zfadfad"
                  })}>
                    copy text
                  </button>
                </li> */}
                {/* <li className="nav-item">
                  <button className="popup-modal-button-my-orders" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Popup
                  </button>
                </li> */}
              </ul>
            </div>
            {/* <div className="ms-lg-auto d-flex align-items-center mt-lg-0 mt-3">
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                <label className="form-check-label trade-text-6 mb-0" for="flexCheckDefault">
                  Hide other pairs
                </label>
              </div>
              <div className="ms-3">
                <button type="button" className="paired-refresh-button"><TfiReload className="me-2" />Refresh</button>
              </div>
            </div> */}
          </div>

          {/* <div className="d-flex flex-lg-row flex-column fle mt-3 px-3 align-items-center gap-lg-0 gap-2">
            <div className="d-flex">
              <button type="button" className="paired-refresh-button me-3">1 Day</button>
              <button type="button" className="paired-refresh-button me-3">1 Week</button>
              <button type="button" className="paired-refresh-button me-3">1 Month</button>
              <button type="button" className="paired-refresh-button me-3">3 Months</button>
            </div>
            <div className="d-flex align-items-center mt-lg-1">
              <span className="trade-text-6 mt-lg-1 me-3">Time</span>
              <DatePicker className="datepicker-css-styling trade-text-6"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              /> <span className="trade-text-6 mt-lg-1 me-3">To</span>
              <DatePicker className="datepicker-css-styling trade-text-6"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
            <div className="me-3 d-flex">
              <button className="spot-table-search-button border-0 me-3" type="button" style={{ backgroundColor: "#14B269" }}>Search</button>
              <button className="spot-table-search-button text-dark" type="button" style={{ backgroundColor: "#F5F8FB" }}>Reset</button>
            </div>
          </div> */}
          {(myProfile && myProfile._id)
            ?
            <>
              <div className="px-2 trading-page-order-table-details-section">
                {userHistoryTab == "open orders" &&
                  <table className="table">
                    <thead>
                      <th>Date</th>
                      <th>Pair</th>
                      <th>Order Type</th>
                      <th>Side</th>
                      <th>Price</th>
                      <th>Amount</th>
                      <th>Filled</th>
                      <th>Remaining</th>
                      <th>Total</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                      {userTradeDetails?.activeOrders?.length > 0 && userTradeDetails?.activeOrders?.map((elem, i) => {
                        const {
                          pair = {},
                          pairName = "",
                          dateTime = "",
                          orderType = "",
                          type = "",
                          filledAmount = 0,
                          amount = 0,
                          price = 0,
                          copyTrade = ""
                        } = elem;
                        const clrClassName = type == 'buy' ? 'color-green' : 'color-red';

                        const {
                          priceDecimal = 0,
                          amountDecimal = 0,
                          totalDecimal = 0,
                          decimalValue = 0
                        } = pair;
                        return <tr>
                          <td data-label="Date">{dateFormat(dateTime)}</td>
                          <td data-label="Pair">{pairName ?
                            <Link to={"/spot/" + pairName}>{pairName.replace("_", "/")}</Link> : ""
                          }
                          </td>
                          <td data-label="Type" className="capitalizeText">{orderType}</td>
                          <td data-label="Type" className={"capitalizeText " + (clrClassName)}>{type}</td>
                          <td data-label="Price">{price}</td>
                          <td data-label="Amount">{props.decimalValue(amount, amountDecimal)}</td>
                          <td data-label="Filled">{props.decimalValue(filledAmount, amountDecimal)}</td>
                          <td data-label="Remaining">{props.decimalValue(amount - filledAmount, amountDecimal)}</td>
                          <td data-label="Total">{props.decimalValue((amount * price), totalDecimal)}</td>
                          {copyTrade == 1 && myProfile._id == elem.TraderID ?
                            <td data-label="Status"><button type="button" className="market-trade-button" onClick={() => props.cancelCopyTradeOrder(elem.copyTradeID)}>Cancel</button></td>
                            :
                            <td data-label="Status"><button type="button" className="market-trade-button" onClick={() => props.cancelOrder(elem._id)}>Cancel</button></td>
                          }
                        </tr>
                      })}
                      {props.userTradeDetails?.activeOrders?.length == 0 &&
                        <tr colSpan={9} style={{ marginTop: "100px" }}>
                          <td className='color-white text-center d-flex flex-column align-items-center gap-2 px-0 border-0' colSpan="7">
                            <img src={notFound} alt="order-notfound" style={{ width: 70, height: 70 }} />
                            <span>No Active Orders Found!</span>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                }
                {userHistoryTab == "Stop Limit Orders" &&
                  <table className="table">
                    <thead>
                      <th>Date</th>
                      <th>Pair</th>
                      <th>Side</th>
                      <th>Stop Price</th>
                      <th>Price</th>
                      <th>Amount</th>
                      <th>Filled</th>
                      <th>Total</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                      {userTradeDetails.stopOrders.length > 0 && userTradeDetails.stopOrders.map((elem, i) => {
                        console.log({elem})
                        const {
                          pair = {},
                          pairName = "",
                          dateTime = "",
                          type = "",
                          stopPrice = 0,
                          price = 0,
                          amount = 0,
                          filledAmount = 0,
                          copyTrade = ""
                        } = elem;
                        const clrClassName = type == 'buy' ? 'color-green' : 'color-red';
                        const {
                          amountDecimal = 0,
                          priceDecimal = 6,
                          totalDecimal = 0,
                        } = pair;
                        return <tr>
                          <td data-label="date">{dateFormat(dateTime)}</td>
                          <td data-label="Pair">
                            {pairName ?
                              <Link to={"/spot/" + pairName}>{pairName.replace("_", "/")}</Link> : ""
                            }
                          </td>
                          <td data-label="Type" className={"capitalizeText " + (clrClassName)}>{type}</td>
                          <td data-label="stop"> {props.decimalValue(stopPrice, totalDecimal)}</td>
                          <td data-label="Price">{props.decimalValue(price, totalDecimal)}</td>
                          <td data-label="Amount">{props.decimalValue(amount, amountDecimal)}</td>
                          <td data-label="Filled">{props.decimalValue(filledAmount, amountDecimal)}</td>
                          <td data-label="Total">{props.decimalValue((amount * price), totalDecimal)}</td>
                          {copyTrade == 1 && myProfile._id == elem.TraderID ?
                            <td data-label="Status"><button type="button" className="market-trade-button" onClick={() => props.cancelCopyTradeOrder(elem.copyTradeID)}>Cancel</button></td>
                            :
                            <td data-label="Status"><button type="button" className="market-trade-button" onClick={() => props.cancelOrder(elem._id)}>Cancel</button></td>
                          }
                        </tr>
                      })}
                      {props.userTradeDetails.stopOrders.length == 0 &&
                        <tr colSpan={8} style={{ marginTop: "100px" }}>
                          <td className='color-white text-center d-flex flex-column align-items-center gap-2 px-0 border-0' colSpan="7">
                            <img src={notFound} alt="order-notfound" style={{ width: 70, height: 70 }} />
                            <span>No Active Orders Found!</span>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                }
                {userHistoryTab == "Trade History" &&
                  <>
                    <TradeHistoryTable
                      traHisData={userTradeDetails.tradeHistory}
                      getOrderDetail_call={getOrderDetail_call}
                      decimalValue={props.decimalValue}
                    />
                    {/* {
                      (userTradeDetails.count &&
                        userTradeDetails.count.tradeHistory &&
                        userTradeDetails.count.tradeHistory / recordsPerPage > 1)
                        ?
                        <Pagination className="pagination"
                          total={Math.ceil(userTradeDetails.count.tradeHistory / recordsPerPage)}
                          current={currentPage_tradeHis}
                          onPageChange={page => clickPageNo(page)}
                        /> :
                        ""
                    } */}
                  </>
                }
              </div>
            </>
            :
            userHistoryTab != "depth-chart" ? (
              <div className='trading-page-order-tabs-section pt-5'>
                <span className='trade-bottom-login-text-1'>
                  <span className='fc-b cursor-poniter' onClick={() => navigate('/login')}>Login </span>(or)
                  <span className='fc-b cursor-poniter' onClick={() => navigate('/register')}> Register Now</span> to trade
                </span>
              </div>
            ) : null
          }
          {userHistoryTab == "depth-chart" && (<>
            <div className="px-2 trading-page-order-table-details-section">
              {
                (!isBinancePair && ((bids.length >= 2 || asks.length >= 2) || (bids.length >= 1 && asks.length >= 1))) || (isBinancePair && (binanceDepthOrders.bids.length >= 2 || binanceDepthOrders.asks.length >= 2)) ? (
                  <DepthChart symbol={symbol} isBinancePair={isBinancePair} bids={bids} asks={asks} binanceDepthOrders={binanceDepthOrders} />
                ) : (
                  <>
                    <div className="color-white text-center d-flex flex-column align-items-center gap-2 px-0 border-0" style={{marginTop:"100px"}}>
                      <img src={depthNotFound} alt="depth-order-notfound" style={{ width: 70, height: 70 }} />
                      <span className=''>No Depth Orders Found!</span>
                    </div>

                  </>
                )
              }
            </div>
          </>)}
        </div>
      </div>

      <TradeHistoryDetail
        traHisDetails={traHisDetails}
        decimalValue={props.decimalValue}
      />

    </div>
  );
}
