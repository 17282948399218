import React, { useState, useEffect } from 'react'
import NavbarOne from "../siteTheme/NavbarOne";
import { FaArrowCircleLeft } from "react-icons/fa";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
// import { BsApple } from "react-icons/bs";
import Bsapple from '../../assets/images/App-Store-1.png'
import Bsplaystore from '../../assets/images/Play-Store.png'
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { toast } from "../../core/lib/toastAlert";
import * as yup from 'yup';
import { Formik } from 'formik';
import { useFormik } from 'formik';
import { useContextData } from '../../core/context/index'
import Config from "../../core/config/";
import { makeRequest } from "../../core/services/v1/request";
import { Navigate, useNavigate } from 'react-router-dom';
import { RiFileCopyFill } from 'react-icons/ri';
import { getCookie, deleteCookie } from '../../core/helper/cookie';
import QrCode from 'react-qr-code';

const validationtfaSchema = yup.object({
  code: yup
    .number('Please enter the 2FA code')
    .required('2FA code is required'),
});

export default function GoogleAuthenticator(props) {

  const navigate = useNavigate();
  const { myProfile, setUserProfile, siteSettings } = useContextData();
  const [isLoading, setisLoading] = useState(false);
  const [TFAQRCode, setTFAQRCode] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  useEffect(() => {
    getTFADetails();
  }, [myProfile, siteSettings])

  async function getTFADetails() {
    if (myProfile) {
      let displayName = siteSettings?.siteName + " - ";

      if (myProfile.username) {
        displayName = displayName + myProfile.username;
      }
      if (myProfile.email) {
        displayName = displayName ? displayName + "(" + myProfile.email + ")" : myProfile.email;
      }
      else if (myProfile.phoneno) {
        displayName = displayName ? displayName + "(" + myProfile.phoneno + ")" : myProfile.phoneno;
      }
      // displayName = displayName + " - " + siteSettings?.siteName;
      let urlencoded = encodeURI('otpauth://totp/' + displayName + '?secret=' + myProfile.secretKey + '&issuer=' + siteSettings?.siteName);
      setQrUrl(urlencoded);
      setTFAQRCode('https://chart.googleapis.com/chart?chs=200x200&chld=M|0&cht=qr&chl=' + urlencoded)
    }
  }

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationtfaSchema,
    onSubmit: async (values, { resetForm }) => {
      setisLoading(true)
      const data = {
        secret: myProfile?.secretKey,
        code: values.code
      }
      const params = {
        url: `${Config.V1_API_URL}user/updateTFA`,
        method: 'POST',
        body: data
      }
      const response = (await makeRequest(params));
      let type = 'error';
      if (response.status) {
        type = 'success';
        if (response.type == 1) {
          deleteCookie('userToken');
          setUserProfile();
          navigate('/login');
        }
        else {
          resetForm({ values: '' });
          setUserProfile();
        }
      }
      setisLoading(false)
      toast({ type, message: response.message });
    },
  });

  function copyText(data = {}) {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.value = data.text;
    input.select();
    document.execCommand("Copy");
    input.remove();
    let message = "";
    if (data.type == "referCode") {
      message = "Referral code copied successfully!";
    }
    else if (data.type == "referId") {
      message = "Referral link copied successfully!";
    }
    else if (data.type == "secretKey") {
      message = "2FA secret key copied successfully!";
    }
    toast({ type: 'success', message });
  }
  return (
    <div>

      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className='container-fluid top-alt-new-ido-section'>
        <div className='row justify-content-center min-vh-100'>
          <div className='col-lg-12'>
            <div class="deposit-hero-section">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col">
                    <h2 className="alt-security-ctn-01 mb-0" onClick={() => navigate("/my/profile")}><MdOutlineKeyboardArrowLeft className='me-5 curPointer deposit-back-button-icon' />Security</h2>
                  </div>
                </div>
              </div>
            </div>
            <h1 className='mt-5 text-center'>Google Authenticator</h1>
            <h4 className='text-center my-4 password-text-55'>Download and install the Authenticator app</h4>
            <center>
              <div className="card bg-transparent card-width-style mt-5 mb-5 border-0">
                <div className="card-body alt-autheticator-color-10">
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-lg-6 mt-3'>
                        {/* <center> */}
                        <div onClick={() => window.open(Config.TFA.downLoadLink.googleApp, 'new_window')} className="cursor curPoiter" style={{ cursor: "pointer" }}>
                          <h1><img className='apple-img-01' src={Bsapple} /></h1>
                          <p className='password-text-33 mb-1'>Download from</p>
                          <h4 className='password-text-55'>App store</h4>
                        </div>
                      </div>
                      <div className='col-lg-6 mt-3'>
                        <div onClick={() => window.open(Config.TFA.downLoadLink.playstoreApp, 'new_window')} className="cursor curPoiter" style={{ cursor: "pointer" }}>
                          <h1><img className='play-store-img-01' src={Bsplaystore} /></h1>
                          <p className='password-text-33 mb-1'>Download from</p>
                          <h4 className='password-text-55'>Play store</h4>
                        </div>
                      </div>
                      <div className='col-lg-12 mt-1 mb-1'>
                        {myProfile?.tfaenablekey == '' ?
                          <>
                            <p><small className='password-text-33 mb-4'>Scan this QR code in the Authenticator app</small></p>
                            {TFAQRCode &&
                              // <img src={TFAQRCode} alt='qrcode' className='img-fluid alt-new-img-fluid' />
                              <div className="text-center">
                                <QrCode
                                  className="qr-bg bg-white p-3"
                                  size={256}
                                  style={{ height: "50%", maxWidth: "100%", width: "30%" }}
                                  value={qrUrl}
                                  viewBox={"0 0 256 256"}
                                />
                              </div>
                            }
                            <div className='mt-3'>
                              <button
                                className='btn-next btn mb-3 text-white'
                                type="button"
                              >
                                {myProfile?.secretKey}
                                {" "}<RiFileCopyFill className='curPointer text-white' onClick={() => copyText({ text: myProfile?.secretKey, type: "secretKey" })} />
                              </button>
                            </div>

                            <p><small className='password-text-33 fw-bold'>Save this key or you may lose access to your account.</small></p>
                          </>
                          :
                          <><div className="form-floating mt-5">Your 2FA is enabled right now.</div></>
                        }
                      </div>

                      {/* </center> */}

                      <div className='col-lg-6 mx-auto'>
                        <form onSubmit={formik.handleSubmit}>
                          <>

                            <div className="form-floating mt-2">
                              <input type="text" className="form-control bg-transparent"
                                // placeholder="Enter 2FA code"
                                name="code"
                                id="code"
                                autoComplete='off'
                                onChange={(e) => {
                                  let value = e.target.value;
                                  value = value.replace(/[^0-9]/g, '');
                                  e.target.value = value;
                                  formik.handleChange(e);
                                }}
                                value={formik.values.code}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                              />
                              {formik.errors.code ? <small className="invalid-code error">{formik.errors.code}</small> : null}
                              <label for="floatingInput">Enter 2FA code</label>
                            </div>
                            <button
                              className={myProfile?.tfaenablekey != '' ? 'btn-next btn my-2 text-white' : 'btn-next btn mt-4 text-white'}
                              type='submit'
                              disabled={isLoading}
                            >
                              {myProfile?.tfaenablekey != '' ? 'Disable' : 'Enable'}
                            </button> <br></br>
                            {/* <button className='btn-next btn my-2 disabled'>Disable</button> */}
                          </>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>
    </div>
  )
}



